@media (orientation: portrait) {
  .sobreText{
    width: 40%;
    margin: 30px auto;
    text-align: center;
    font-size: 1em;
  }

}
@media (orientation: landscape) {
  .sobreText{
    width: 80%;
    margin: 30px auto;
    text-align: center;
    font-size: 1.3em;
  }
}
