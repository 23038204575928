@media (min-width: 800px){
  .frontPublicity {
    position: relative;
  }

  .frontImage{
    width: 100%
  }
  .topLeftTextImage{
    position: absolute;
    width: 50%;
    top:100px;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
  }
  .textImages{
    font-size: 2em;
  }

  .ferramentasPlataforma{
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cardFerramenta{
    width: 18rem;
    text-align: center;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
  }

  .resumoPublicidade{
    margin-top: 30px;
    background-color: #ea4545;
    padding: 75px;
    text-align: justify;
    font-weight: bold;
    font-size: 1.5em;
  }

  .icentivo{
    margin-top: 30px;
    background-color: #ea4545;
    padding: 75px;
    text-align: justify;
    font-weight: bold;
    font-size: 1.5em;
  }
  .responsiveImgL{
    display: inherit;
  }
  .responsiveImgP{
    display: none;
  }

}

@media (max-width: 1000px) {
  .textImageIcentivoProf{
    font-size: 1.3em;
  }
}

@media (max-width: 800px){

  .topLeftTextImage{
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
    margin-top: 30px;
    padding: 75px;
  }
  .textImages{
    font-size: 1.3em;
  }
  .frontImage{
    width: 100%
  }

  .ferramentasPlataforma{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cardFerramenta{
    width: 18rem;
    text-align: center;
    padding: 15px;
    margin: 10px auto;
  }

  .resumoPublicidade{
    margin-top: 30px;
    background-color: #ea4545;
    padding: 75px;
    text-align: justify;
    font-weight: bold;
    font-size: 1em;
  }

  .icentivo{
    margin-top: 30px;
    padding: 75px;
    background-color: #ea4545;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
  }
  .responsiveImgL{
    display: none;
  }
  .responsiveImgP{
    display: inherit;
  }
}
