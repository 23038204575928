.cardEditor{
  margin-top: 20px
}

.letraCheck{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: white;
}

.imagemQ{
  max-width: 75%;
}
