@media (orientation: landscape) {
  .navbarTopStyle{
    padding: 10px 100px 10px 100px;
  }

  .navBrandImgStyle{
    height: 2em;
  }
}

@media (orientation: portrait) {
  .navbarTopStyle{
    padding: 10px;
  }

  .navBrandImgStyle{
    height: 2em;
  }

  .navBarCItens{
    margin-top: 15px;
  }

  .navPortrait{
    text-align: right;
  }
}

.divImg{
  border-radius: 100%;
  width: 2em;
  height: 2em;
  background-color: #00aeef;
  border: solid;
  border-width: thin;
  border-color: black;
  display: flex;
  justify-content: center;
}
.divImg:hover{
    background-color: #FF8C00;
}
