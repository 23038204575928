.questao{
  margin-top: 20px;
}

.questaoCard{
  padding: 20px;
}

.letraCheck{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.letraInterna{
  margin:auto;
}

.respostaView{
  cursor: pointer;
}
