.buttonContainer{
     height:100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

    }

.imgUpload{
      width: 300px;
}
