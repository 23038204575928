table {
  border: 1px solid black;
  table-layout: auto;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
  text-align: center;
  width: 80%;
}

th, td {
  border: 1px solid black;
  width: 100px;
  overflow: hidden;
  margin-bottom: 30px;
}

.imgMark{
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.imgMark2{
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}



@media (max-width: 700px){
  .imgMark{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .imgMark2{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  table {
    font-size: 10px;
    border: 1px solid black;
    table-layout: auto;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;
    text-align: center;
    width: 80%;
  }

  th, td {
    border: 1px solid black;
    overflow: hidden;
    margin-bottom: 30px;
  }

}
