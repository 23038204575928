.dropdown {
  position: relative;
  display: inline-block;
  /*  */
  border-radius: 100%;
  width: 2em;
  height: 2em;
  background-color: #00aeef;
  border: solid;
  border-width: thin;
  border-color: black;

}

.dropdownContent {
  position: absolute;
  margin-top: 10px;
  background-color: #f9f9f9;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover{
    background-color: #FF8C00;
    cursor: pointer;
}
