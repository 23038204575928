.acessoLayout{
  margin: 100px 25px
}

.imgAcesso{
  padding: 0% 20% 0 20%;
}

.divButtonLR{
  display: flex;
  justify-content: center;
}

.buttonLR{
  width: 100%;
  background-color: white;
  color: black;
  border: none;
}
