.conteudoCard{
  margin: 30px 30px;
  padding: 30px 30px;
}

.conteudosCard{
  margin: 30px 30px;
  padding: 30px 30px;
}

.aConteudo{
  margin: 20px
}
